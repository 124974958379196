import { render, staticRenderFns } from "./ProdutoController.vue?vue&type=template&id=37aa4d67&scoped=true&"
import script from "./ProdutoController.vue?vue&type=script&lang=js&"
export * from "./ProdutoController.vue?vue&type=script&lang=js&"
import style0 from "./ProdutoController.vue?vue&type=style&index=0&id=37aa4d67&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37aa4d67",
  null
  
)

export default component.exports