<!-- eslint-disable no-unused-vars -->
<template>
    <div v-if="qrCodeLido == null">
        <div class="qr-body">
            <h5>Por favor, aponte o código de barras da sua comanda para o reconhecimento.</h5>
            <br>
            <!-- <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded" /> -->


            <div class="qr-camera">
                <video id="webcam-preview" ref="videopreview" style="width: 100%; height: 250px;" autoplay></video>
                <div class="scan-bar-anim"></div>
                <p id="result"></p>
            </div>
            
        </div> 
    </div>
</template>

<script>
// import { StreamBarcodeReader } from "@teckel/vue-barcode-reader";
// eslint-disable-next-line no-unused-vars
import { BarcodeFormat, DecodeHintType,  NotFoundException, ChecksumException, FormatException, BinaryBitmap, HybridBinarizer, RGBLuminanceSource, BrowserMultiFormatReader } from '@zxing/library';

export default {
    name: "QrcodeScanner",
    data() {
        return {
            qrCodeLido: null,
            videoDevicesRecog: null,
            videoElem: null,
        }
    },
    mounted() {
        this.onCreate()
    },
    beforeDestroy() {
        console.log("saindo da camera")
        // aqui vamos parar a camera
        this.videoElem.srcObject.getTracks().forEach((track) => {
            if (track.readyState == 'live') {
                track.stop(); // para a camera por track
            }
        });
    },
    methods: {
        async onCreate() {
            const hints = new Map();
            const formats = [BarcodeFormat.QR_CODE, BarcodeFormat.DATA_MATRIX, BarcodeFormat.UPC_A, BarcodeFormat.UPC_E, BarcodeFormat.AZTEC,];

            hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);

            // o elemento HTML
            this.videoElem = this.$refs.videopreview

            // Get a stream for the rear camera, else the front (or side?) camera.
            this.videoElem.srcObject = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' }, audio: false });
            
            // vamos criar uma instancia do listener
            const reader = new BrowserMultiFormatReader();

            // aqui esta o listener
            reader.decodeFromVideo(this.videoElem)
                .then((val) => {

                    // aqui vamos parar a camea
                    this.videoElem.srcObject.getTracks().forEach((track) => {
                        if (track.readyState == 'live') {
                            track.stop(); // para a camera por track
                        }
                    });

                    // vamos setar essa var para sumir todos os elementos HTML
                    this.qrCodeLido = val.text

                    // vamos mandar pro parent o qrcode ou barcode ligo
                    this.$emit("onBarcodeLido", val.text)

                })
                .catch((err) => console.log(err))
        },
    },
    components: {
        // QrcodeStream
        // StreamBarcodeReader
    }
}
</script>

<style scoped>
.qr-body {
    margin-block: 25px;
}
.qr-camera {
    width: 100%; height: 250px;
    position: relative;
}
.webcam-preview {
    position: absolute;
}
.scan-bar-anim {
    position: absolute;
    width: 80%;
    margin-inline: 10%;
    height: 2px;
    top: 25px;
    background: red;
    animation: scan-bar-anim 1.8s infinite;
}
@keyframes scan-bar-anim {
    0%, 100% { top: 70px  }
    50%      { top: 180px }
}
</style>